(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);throw new Error("Cannot find module '"+o+"'")}var f=n[o]={exports:{}};t[o][0].call(f.exports,function(e){var n=t[o][1][e];return s(n?n:e)},f,f.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
var lookup = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';

;(function (exports) {
	'use strict';

  var Arr = (typeof Uint8Array !== 'undefined')
    ? Uint8Array
    : Array

	var PLUS   = '+'.charCodeAt(0)
	var SLASH  = '/'.charCodeAt(0)
	var NUMBER = '0'.charCodeAt(0)
	var LOWER  = 'a'.charCodeAt(0)
	var UPPER  = 'A'.charCodeAt(0)
	var PLUS_URL_SAFE = '-'.charCodeAt(0)
	var SLASH_URL_SAFE = '_'.charCodeAt(0)

	function decode (elt) {
		var code = elt.charCodeAt(0)
		if (code === PLUS ||
		    code === PLUS_URL_SAFE)
			return 62 // '+'
		if (code === SLASH ||
		    code === SLASH_URL_SAFE)
			return 63 // '/'
		if (code < NUMBER)
			return -1 //no match
		if (code < NUMBER + 10)
			return code - NUMBER + 26 + 26
		if (code < UPPER + 26)
			return code - UPPER
		if (code < LOWER + 26)
			return code - LOWER + 26
	}

	function b64ToByteArray (b64) {
		var i, j, l, tmp, placeHolders, arr

		if (b64.length % 4 > 0) {
			throw new Error('Invalid string. Length must be a multiple of 4')
		}

		// the number of equal signs (place holders)
		// if there are two placeholders, than the two characters before it
		// represent one byte
		// if there is only one, then the three characters before it represent 2 bytes
		// this is just a cheap hack to not do indexOf twice
		var len = b64.length
		placeHolders = '=' === b64.charAt(len - 2) ? 2 : '=' === b64.charAt(len - 1) ? 1 : 0

		// base64 is 4/3 + up to two characters of the original data
		arr = new Arr(b64.length * 3 / 4 - placeHolders)

		// if there are placeholders, only get up to the last complete 4 chars
		l = placeHolders > 0 ? b64.length - 4 : b64.length

		var L = 0

		function push (v) {
			arr[L++] = v
		}

		for (i = 0, j = 0; i < l; i += 4, j += 3) {
			tmp = (decode(b64.charAt(i)) << 18) | (decode(b64.charAt(i + 1)) << 12) | (decode(b64.charAt(i + 2)) << 6) | decode(b64.charAt(i + 3))
			push((tmp & 0xFF0000) >> 16)
			push((tmp & 0xFF00) >> 8)
			push(tmp & 0xFF)
		}

		if (placeHolders === 2) {
			tmp = (decode(b64.charAt(i)) << 2) | (decode(b64.charAt(i + 1)) >> 4)
			push(tmp & 0xFF)
		} else if (placeHolders === 1) {
			tmp = (decode(b64.charAt(i)) << 10) | (decode(b64.charAt(i + 1)) << 4) | (decode(b64.charAt(i + 2)) >> 2)
			push((tmp >> 8) & 0xFF)
			push(tmp & 0xFF)
		}

		return arr
	}

	function uint8ToBase64 (uint8) {
		var i,
			extraBytes = uint8.length % 3, // if we have 1 byte left, pad 2 bytes
			output = "",
			temp, length

		function encode (num) {
			return lookup.charAt(num)
		}

		function tripletToBase64 (num) {
			return encode(num >> 18 & 0x3F) + encode(num >> 12 & 0x3F) + encode(num >> 6 & 0x3F) + encode(num & 0x3F)
		}

		// go through the array every three bytes, we'll deal with trailing stuff later
		for (i = 0, length = uint8.length - extraBytes; i < length; i += 3) {
			temp = (uint8[i] << 16) + (uint8[i + 1] << 8) + (uint8[i + 2])
			output += tripletToBase64(temp)
		}

		// pad the end with zeros, but make sure to not forget the extra bytes
		switch (extraBytes) {
			case 1:
				temp = uint8[uint8.length - 1]
				output += encode(temp >> 2)
				output += encode((temp << 4) & 0x3F)
				output += '=='
				break
			case 2:
				temp = (uint8[uint8.length - 2] << 8) + (uint8[uint8.length - 1])
				output += encode(temp >> 10)
				output += encode((temp >> 4) & 0x3F)
				output += encode((temp << 2) & 0x3F)
				output += '='
				break
		}

		return output
	}

	exports.toByteArray = b64ToByteArray
	exports.fromByteArray = uint8ToBase64
}(typeof exports === 'undefined' ? (this.base64js = {}) : exports))

}).call(this,require("pBGvAp"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/../node_modules/base64-js/lib/b64.js","/../node_modules/base64-js/lib")
},{"buffer":2,"pBGvAp":4}],2:[function(require,module,exports){
(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
/*!
 * The buffer module from node.js, for the browser.
 *
 * @author   Feross Aboukhadijeh <feross@feross.org> <http://feross.org>
 * @license  MIT
 */

var base64 = require('base64-js')
var ieee754 = require('ieee754')

exports.Buffer = Buffer
exports.SlowBuffer = Buffer
exports.INSPECT_MAX_BYTES = 50
Buffer.poolSize = 8192

/**
 * If `Buffer._useTypedArrays`:
 *   === true    Use Uint8Array implementation (fastest)
 *   === false   Use Object implementation (compatible down to IE6)
 */
Buffer._useTypedArrays = (function () {
  // Detect if browser supports Typed Arrays. Supported browsers are IE 10+, Firefox 4+,
  // Chrome 7+, Safari 5.1+, Opera 11.6+, iOS 4.2+. If the browser does not support adding
  // properties to `Uint8Array` instances, then that's the same as no `Uint8Array` support
  // because we need to be able to add all the node Buffer API methods. This is an issue
  // in Firefox 4-29. Now fixed: https://bugzilla.mozilla.org/show_bug.cgi?id=695438
  try {
    var buf = new ArrayBuffer(0)
    var arr = new Uint8Array(buf)
    arr.foo = function () { return 42 }
    return 42 === arr.foo() &&
        typeof arr.subarray === 'function' // Chrome 9-10 lack `subarray`
  } catch (e) {
    return false
  }
})()

/**
 * Class: Buffer
 * =============
 *
 * The Buffer constructor returns instances of `Uint8Array` that are augmented
 * with function properties for all the node `Buffer` API functions. We use
 * `Uint8Array` so that square bracket notation works as expected -- it returns
 * a single octet.
 *
 * By augmenting the instances, we can avoid modifying the `Uint8Array`
 * prototype.
 */
function Buffer (subject, encoding, noZero) {
  if (!(this instanceof Buffer))
    return new Buffer(subject, encoding, noZero)

  var type = typeof subject

  // Workaround: node's base64 implementation allows for non-padded strings
  // while base64-js does not.
  if (encoding === 'base64' && type === 'string') {
    subject = stringtrim(subject)
    while (subject.length % 4 !== 0) {
      subject = subject + '='
    }
  }

  // Find the length
  var length
  if (type === 'number')
    length = coerce(subject)
  else if (type === 'string')
    length = Buffer.byteLength(subject, encoding)
  else if (type === 'object')
    length = coerce(subject.length) // assume that object is array-like
  else
    throw new Error('First argument needs to be a number, array or string.')

  var buf
  if (Buffer._useTypedArrays) {
    // Preferred: Return an augmented `Uint8Array` instance for best performance
    buf = Buffer._augment(new Uint8Array(length))
  } else {
    // Fallback: Return THIS instance of Buffer (created by `new`)
    buf = this
    buf.length = length
    buf._isBuffer = true
  }

  var i
  if (Buffer._useTypedArrays && typeof subject.byteLength === 'number') {
    // Speed optimization -- use set if we're copying from a typed array
    buf._set(subject)
  } else if (isArrayish(subject)) {
    // Treat array-ish objects as a byte array
    for (i = 0; i < length; i++) {
      if (Buffer.isBuffer(subject))
        buf[i] = subject.readUInt8(i)
      else
        buf[i] = subject[i]
    }
  } else if (type === 'string') {
    buf.write(subject, 0, encoding)
  } else if (type === 'number' && !Buffer._useTypedArrays && !noZero) {
    for (i = 0; i < length; i++) {
      buf[i] = 0
    }
  }

  return buf
}

// STATIC METHODS
// ==============

Buffer.isEncoding = function (encoding) {
  switch (String(encoding).toLowerCase()) {
    case 'hex':
    case 'utf8':
    case 'utf-8':
    case 'ascii':
    case 'binary':
    case 'base64':
    case 'raw':
    case 'ucs2':
    case 'ucs-2':
    case 'utf16le':
    case 'utf-16le':
      return true
    default:
      return false
  }
}

Buffer.isBuffer = function (b) {
  return !!(b !== null && b !== undefined && b._isBuffer)
}

Buffer.byteLength = function (str, encoding) {
  var ret
  str = str + ''
  switch (encoding || 'utf8') {
    case 'hex':
      ret = str.length / 2
      break
    case 'utf8':
    case 'utf-8':
      ret = utf8ToBytes(str).length
      break
    case 'ascii':
    case 'binary':
    case 'raw':
      ret = str.length
      break
    case 'base64':
      ret = base64ToBytes(str).length
      break
    case 'ucs2':
    case 'ucs-2':
    case 'utf16le':
    case 'utf-16le':
      ret = str.length * 2
      break
    default:
      throw new Error('Unknown encoding')
  }
  return ret
}

Buffer.concat = function (list, totalLength) {
  assert(isArray(list), 'Usage: Buffer.concat(list, [totalLength])\n' +
      'list should be an Array.')

  if (list.length === 0) {
    return new Buffer(0)
  } else if (list.length === 1) {
    return list[0]
  }

  var i
  if (typeof totalLength !== 'number') {
    totalLength = 0
    for (i = 0; i < list.length; i++) {
      totalLength += list[i].length
    }
  }

  var buf = new Buffer(totalLength)
  var pos = 0
  for (i = 0; i < list.length; i++) {
    var item = list[i]
    item.copy(buf, pos)
    pos += item.length
  }
  return buf
}

// BUFFER INSTANCE METHODS
// =======================

function _hexWrite (buf, string, offset, length) {
  offset = Number(offset) || 0
  var remaining = buf.length - offset
  if (!length) {
    length = remaining
  } else {
    length = Number(length)
    if (length > remaining) {
      length = remaining
    }
  }

  // must be an even number of digits
  var strLen = string.length
  assert(strLen % 2 === 0, 'Invalid hex string')

  if (length > strLen / 2) {
    length = strLen / 2
  }
  for (var i = 0; i < length; i++) {
    var byte = parseInt(string.substr(i * 2, 2), 16)
    assert(!isNaN(byte), 'Invalid hex string')
    buf[offset + i] = byte
  }
  Buffer._charsWritten = i * 2
  return i
}

function _utf8Write (buf, string, offset, length) {
  var charsWritten = Buffer._charsWritten =
    blitBuffer(utf8ToBytes(string), buf, offset, length)
  return charsWritten
}

function _asciiWrite (buf, string, offset, length) {
  var charsWritten = Buffer._charsWritten =
    blitBuffer(asciiToBytes(string), buf, offset, length)
  return charsWritten
}

function _binaryWrite (buf, string, offset, length) {
  return _asciiWrite(buf, string, offset, length)
}

function _base64Write (buf, string, offset, length) {
  var charsWritten = Buffer._charsWritten =
    blitBuffer(base64ToBytes(string), buf, offset, length)
  return charsWritten
}

function _utf16leWrite (buf, string, offset, length) {
  var charsWritten = Buffer._charsWritten =
    blitBuffer(utf16leToBytes(string), buf, offset, length)
  return charsWritten
}

Buffer.prototype.write = function (string, offset, length, encoding) {
  // Support both (string, offset, length, encoding)
  // and the legacy (string, encoding, offset, length)
  if (isFinite(offset)) {
    if (!isFinite(length)) {
      encoding = length
      length = undefined
    }
  } else {  // legacy
    var swap = encoding
    encoding = offset
    offset = length
    length = swap
  }

  offset = Number(offset) || 0
  var remaining = this.length - offset
  if (!length) {
    length = remaining
  } else {
    length = Number(length)
    if (length > remaining) {
      length = remaining
    }
  }
  encoding = String(encoding || 'utf8').toLowerCase()

  var ret
  switch (encoding) {
    case 'hex':
      ret = _hexWrite(this, string, offset, length)
      break
    case 'utf8':
    case 'utf-8':
      ret = _utf8Write(this, string, offset, length)
      break
    case 'ascii':
      ret = _asciiWrite(this, string, offset, length)
      break
    case 'binary':
      ret = _binaryWrite(this, string, offset, length)
      break
    case 'base64':
      ret = _base64Write(this, string, offset, length)
      break
    case 'ucs2':
    case 'ucs-2':
    case 'utf16le':
    case 'utf-16le':
      ret = _utf16leWrite(this, string, offset, length)
      break
    default:
      throw new Error('Unknown encoding')
  }
  return ret
}

Buffer.prototype.toString = function (encoding, start, end) {
  var self = this

  encoding = String(encoding || 'utf8').toLowerCase()
  start = Number(start) || 0
  end = (end !== undefined)
    ? Number(end)
    : end = self.length

  // Fastpath empty strings
  if (end === start)
    return ''

  var ret
  switch (encoding) {
    case 'hex':
      ret = _hexSlice(self, start, end)
      break
    case 'utf8':
    case 'utf-8':
      ret = _utf8Slice(self, start, end)
      break
    case 'ascii':
      ret = _asciiSlice(self, start, end)
      break
    case 'binary':
      ret = _binarySlice(self, start, end)
      break
    case 'base64':
      ret = _base64Slice(self, start, end)
      break
    case 'ucs2':
    case 'ucs-2':
    case 'utf16le':
    case 'utf-16le':
      ret = _utf16leSlice(self, start, end)
      break
    default:
      throw new Error('Unknown encoding')
  }
  return ret
}

Buffer.prototype.toJSON = function () {
  return {
    type: 'Buffer',
    data: Array.prototype.slice.call(this._arr || this, 0)
  }
}

// copy(targetBuffer, targetStart=0, sourceStart=0, sourceEnd=buffer.length)
Buffer.prototype.copy = function (target, target_start, start, end) {
  var source = this

  if (!start) start = 0
  if (!end && end !== 0) end = this.length
  if (!target_start) target_start = 0

  // Copy 0 bytes; we're done
  if (end === start) return
  if (target.length === 0 || source.length === 0) return

  // Fatal error conditions
  assert(end >= start, 'sourceEnd < sourceStart')
  assert(target_start >= 0 && target_start < target.length,
      'targetStart out of bounds')
  assert(start >= 0 && start < source.length, 'sourceStart out of bounds')
  assert(end >= 0 && end <= source.length, 'sourceEnd out of bounds')

  // Are we oob?
  if (end > this.length)
    end = this.length
  if (target.length - target_start < end - start)
    end = target.length - target_start + start

  var len = end - start

  if (len < 100 || !Buffer._useTypedArrays) {
    for (var i = 0; i < len; i++)
      target[i + target_start] = this[i + start]
  } else {
    target._set(this.subarray(start, start + len), target_start)
  }
}

function _base64Slice (buf, start, end) {
  if (start === 0 && end === buf.length) {
    return base64.fromByteArray(buf)
  } else {
    return base64.fromByteArray(buf.slice(start, end))
  }
}

function _utf8Slice (buf, start, end) {
  var res = ''
  var tmp = ''
  end = Math.min(buf.length, end)

  for (var i = start; i < end; i++) {
    if (buf[i] <= 0x7F) {
      res += decodeUtf8Char(tmp) + String.fromCharCode(buf[i])
      tmp = ''
    } else {
      tmp += '%' + buf[i].toString(16)
    }
  }

  return res + decodeUtf8Char(tmp)
}

function _asciiSlice (buf, start, end) {
  var ret = ''
  end = Math.min(buf.length, end)

  for (var i = start; i < end; i++)
    ret += String.fromCharCode(buf[i])
  return ret
}

function _binarySlice (buf, start, end) {
  return _asciiSlice(buf, start, end)
}

function _hexSlice (buf, start, end) {
  var len = buf.length

  if (!start || start < 0) start = 0
  if (!end || end < 0 || end > len) end = len

  var out = ''
  for (var i = start; i < end; i++) {
    out += toHex(buf[i])
  }
  return out
}

function _utf16leSlice (buf, start, end) {
  var bytes = buf.slice(start, end)
  var res = ''
  for (var i = 0; i < bytes.length; i += 2) {
    res += String.fromCharCode(bytes[i] + bytes[i+1] * 256)
  }
  return res
}

Buffer.prototype.slice = function (start, end) {
  var len = this.length
  start = clamp(start, len, 0)
  end = clamp(end, len, len)

  if (Buffer._useTypedArrays) {
    return Buffer._augment(this.subarray(start, end))
  } else {
    var sliceLen = end - start
    var newBuf = new Buffer(sliceLen, undefined, true)
    for (var i = 0; i < sliceLen; i++) {
      newBuf[i] = this[i + start]
    }
    return newBuf
  }
}

// `get` will be removed in Node 0.13+
Buffer.prototype.get = function (offset) {
  console.log('.get() is deprecated. Access using array indexes instead.')
  return this.readUInt8(offset)
}

// `set` will be removed in Node 0.13+
Buffer.prototype.set = function (v, offset) {
  console.log('.set() is deprecated. Access using array indexes instead.')
  return this.writeUInt8(v, offset)
}

Buffer.prototype.readUInt8 = function (offset, noAssert) {
  if (!noAssert) {
    assert(offset !== undefined && offset !== null, 'missing offset')
    assert(offset < this.length, 'Trying to read beyond buffer length')
  }

  if (offset >= this.length)
    return

  return this[offset]
}

function _readUInt16 (buf, offset, littleEndian, noAssert) {
  if (!noAssert) {
    assert(typeof littleEndian === 'boolean', 'missing or invalid endian')
    assert(offset !== undefined && offset !== null, 'missing offset')
    assert(offset + 1 < buf.length, 'Trying to read beyond buffer length')
  }

  var len = buf.length
  if (offset >= len)
    return

  var val
  if (littleEndian) {
    val = buf[offset]
    if (offset + 1 < len)
      val |= buf[offset + 1] << 8
  } else {
    val = buf[offset] << 8
    if (offset + 1 < len)
      val |= buf[offset + 1]
  }
  return val
}

Buffer.prototype.readUInt16LE = function (offset, noAssert) {
  return _readUInt16(this, offset, true, noAssert)
}

Buffer.prototype.readUInt16BE = function (offset, noAssert) {
  return _readUInt16(this, offset, false, noAssert)
}

function _readUInt32 (buf, offset, littleEndian, noAssert) {
  if (!noAssert) {
    assert(typeof littleEndian === 'boolean', 'missing or invalid endian')
    assert(offset !== undefined && offset !== null, 'missing offset')
    assert(offset + 3 < buf.length, 'Trying to read beyond buffer length')
  }

  var len = buf.length
  if (offset >= len)
    return

  var val
  if (littleEndian) {
    if (offset + 2 < len)
      val = buf[offset + 2] << 16
    if (offset + 1 < len)
      val |= buf[offset + 1] << 8
    val |= buf[offset]
    if (offset + 3 < len)
      val = val + (buf[offset + 3] << 24 >>> 0)
  } else {
    if (offset + 1 < len)
      val = buf[offset + 1] << 16
    if (offset + 2 < len)
      val |= buf[offset + 2] << 8
    if (offset + 3 < len)
      val |= buf[offset + 3]
    val = val + (buf[offset] << 24 >>> 0)
  }
  return val
}

Buffer.prototype.readUInt32LE = function (offset, noAssert) {
  return _readUInt32(this, offset, true, noAssert)
}

Buffer.prototype.readUInt32BE = function (offset, noAssert) {
  return _readUInt32(this, offset, false, noAssert)
}

Buffer.prototype.readInt8 = function (offset, noAssert) {
  if (!noAssert) {
    assert(offset !== undefined && offset !== null,
        'missing offset')
    assert(offset < this.length, 'Trying to read beyond buffer length')
  }

  if (offset >= this.length)
    return

  var neg = this[offset] & 0x80
  if (neg)
    return (0xff - this[offset] + 1) * -1
  else
    return this[offset]
}

function _readInt16 (buf, offset, littleEndian, noAssert) {
  if (!noAssert) {
    assert(typeof littleEndian === 'boolean', 'missing or invalid endian')
    assert(offset !== undefined && offset !== null, 'missing offset')
    assert(offset + 1 < buf.length, 'Trying to read beyond buffer length')
  }

  var len = buf.length
  if (offset >= len)
    return

  var val = _readUInt16(buf, offset, littleEndian, true)
  var neg = val & 0x8000
  if (neg)
    return (0xffff - val + 1) * -1
  else
    return val
}

Buffer.prototype.readInt16LE = function (offset, noAssert) {
  return _readInt16(this, offset, true, noAssert)
}

Buffer.prototype.readInt16BE = function (offset, noAssert) {
  return _readInt16(this, offset, false, noAssert)
}

function _readInt32 (buf, offset, littleEndian, noAssert) {
  if (!noAssert) {
    assert(typeof littleEndian === 'boolean', 'missing or invalid endian')
    assert(offset !== undefined && offset !== null, 'missing offset')
    assert(offset + 3 < buf.length, 'Trying to read beyond buffer length')
  }

  var len = buf.length
  if (offset >= len)
    return

  var val = _readUInt32(buf, offset, littleEndian, true)
  var neg = val & 0x80000000
  if (neg)
    return (0xffffffff - val + 1) * -1
  else
    return val
}

Buffer.prototype.readInt32LE = function (offset, noAssert) {
  return _readInt32(this, offset, true, noAssert)
}

Buffer.prototype.readInt32BE = function (offset, noAssert) {
  return _readInt32(this, offset, false, noAssert)
}

function _readFloat (buf, offset, littleEndian, noAssert) {
  if (!noAssert) {
    assert(typeof littleEndian === 'boolean', 'missing or invalid endian')
    assert(offset + 3 < buf.length, 'Trying to read beyond buffer length')
  }

  return ieee754.read(buf, offset, littleEndian, 23, 4)
}

Buffer.prototype.readFloatLE = function (offset, noAssert) {
  return _readFloat(this, offset, true, noAssert)
}

Buffer.prototype.readFloatBE = function (offset, noAssert) {
  return _readFloat(this, offset, false, noAssert)
}

function _readDouble (buf, offset, littleEndian, noAssert) {
  if (!noAssert) {
    assert(typeof littleEndian === 'boolean', 'missing or invalid endian')
    assert(offset + 7 < buf.length, 'Trying to read beyond buffer length')
  }

  return ieee754.read(buf, offset, littleEndian, 52, 8)
}

Buffer.prototype.readDoubleLE = function (offset, noAssert) {
  return _readDouble(this, offset, true, noAssert)
}

Buffer.prototype.readDoubleBE = function (offset, noAssert) {
  return _readDouble(this, offset, false, noAssert)
}

Buffer.prototype.writeUInt8 = function (value, offset, noAssert) {
  if (!noAssert) {
    assert(value !== undefined && value !== null, 'missing value')
    assert(offset !== undefined && offset !== null, 'missing offset')
    assert(offset < this.length, 'trying to write beyond buffer length')
    verifuint(value, 0xff)
  }

  if (offset >= this.length) return

  this[offset] = value
}

function _writeUInt16 (buf, value, offset, littleEndian, noAssert) {
  if (!noAssert) {
    assert(value !== undefined && value !== null, 'missing value')
    assert(typeof littleEndian === 'boolean', 'missing or invalid endian')
    assert(offset !== undefined && offset !== null, 'missing offset')
    assert(offset + 1 < buf.length, 'trying to write beyond buffer length')
    verifuint(value, 0xffff)
  }

  var len = buf.length
  if (offset >= len)
    return

  for (var i = 0, j = Math.min(len - offset, 2); i < j; i++) {
    buf[offset + i] =
        (value & (0xff << (8 * (littleEndian ? i : 1 - i)))) >>>
            (littleEndian ? i : 1 - i) * 8
  }
}

Buffer.prototype.writeUInt16LE = function (value, offset, noAssert) {
  _writeUInt16(this, value, offset, true, noAssert)
}

Buffer.prototype.writeUInt16BE = function (value, offset, noAssert) {
  _writeUInt16(this, value, offset, false, noAssert)
}

function _writeUInt32 (buf, value, offset, littleEndian, noAssert) {
  if (!noAssert) {
    assert(value !== undefined && value !== null, 'missing value')
    assert(typeof littleEndian === 'boolean', 'missing or invalid endian')
    assert(offset !== undefined && offset !== null, 'missing offset')
    assert(offset + 3 < buf.length, 'trying to write beyond buffer length')
    verifuint(value, 0xffffffff)
  }

  var len = buf.length
  if (offset >= len)
    return

  for (var i = 0, j = Math.min(len - offset, 4); i < j; i++) {
    buf[offset + i] =
        (value >>> (littleEndian ? i : 3 - i) * 8) & 0xff
  }
}

Buffer.prototype.writeUInt32LE = function (value, offset, noAssert) {
  _writeUInt32(this, value, offset, true, noAssert)
}

Buffer.prototype.writeUInt32BE = function (value, offset, noAssert) {
  _writeUInt32(this, value, offset, false, noAssert)
}

Buffer.prototype.writeInt8 = function (value, offset, noAssert) {
  if (!noAssert) {
    assert(value !== undefined && value !== null, 'missing value')
    assert(offset !== undefined && offset !== null, 'missing offset')
    assert(offset < this.length, 'Trying to write beyond buffer length')
    verifsint(value, 0x7f, -0x80)
  }

  if (offset >= this.length)
    return

  if (value >= 0)
    this.writeUInt8(value, offset, noAssert)
  else
    this.writeUInt8(0xff + value + 1, offset, noAssert)
}

function _writeInt16 (buf, value, offset, littleEndian, noAssert) {
  if (!noAssert) {
    assert(value !== undefined && value !== null, 'missing value')
    assert(typeof littleEndian === 'boolean', 'missing or invalid endian')
    assert(offset !== undefined && offset !== null, 'missing offset')
    assert(offset + 1 < buf.length, 'Trying to write beyond buffer length')
    verifsint(value, 0x7fff, -0x8000)
  }

  var len = buf.length
  if (offset >= len)
    return

  if (value >= 0)
    _writeUInt16(buf, value, offset, littleEndian, noAssert)
  else
    _writeUInt16(buf, 0xffff + value + 1, offset, littleEndian, noAssert)
}

Buffer.prototype.writeInt16LE = function (value, offset, noAssert) {
  _writeInt16(this, value, offset, true, noAssert)
}

Buffer.prototype.writeInt16BE = function (value, offset, noAssert) {
  _writeInt16(this, value, offset, false, noAssert)
}

function _writeInt32 (buf, value, offset, littleEndian, noAssert) {
  if (!noAssert) {
    assert(value !== undefined && value !== null, 'missing value')
    assert(typeof littleEndian === 'boolean', 'missing or invalid endian')
    assert(offset !== undefined && offset !== null, 'missing offset')
    assert(offset + 3 < buf.length, 'Trying to write beyond buffer length')
    verifsint(value, 0x7fffffff, -0x80000000)
  }

  var len = buf.length
  if (offset >= len)
    return

  if (value >= 0)
    _writeUInt32(buf, value, offset, littleEndian, noAssert)
  else
    _writeUInt32(buf, 0xffffffff + value + 1, offset, littleEndian, noAssert)
}

Buffer.prototype.writeInt32LE = function (value, offset, noAssert) {
  _writeInt32(this, value, offset, true, noAssert)
}

Buffer.prototype.writeInt32BE = function (value, offset, noAssert) {
  _writeInt32(this, value, offset, false, noAssert)
}

function _writeFloat (buf, value, offset, littleEndian, noAssert) {
  if (!noAssert) {
    assert(value !== undefined && value !== null, 'missing value')
    assert(typeof littleEndian === 'boolean', 'missing or invalid endian')
    assert(offset !== undefined && offset !== null, 'missing offset')
    assert(offset + 3 < buf.length, 'Trying to write beyond buffer length')
    verifIEEE754(value, 3.4028234663852886e+38, -3.4028234663852886e+38)
  }

  var len = buf.length
  if (offset >= len)
    return

  ieee754.write(buf, value, offset, littleEndian, 23, 4)
}

Buffer.prototype.writeFloatLE = function (value, offset, noAssert) {
  _writeFloat(this, value, offset, true, noAssert)
}

Buffer.prototype.writeFloatBE = function (value, offset, noAssert) {
  _writeFloat(this, value, offset, false, noAssert)
}

function _writeDouble (buf, value, offset, littleEndian, noAssert) {
  if (!noAssert) {
    assert(value !== undefined && value !== null, 'missing value')
    assert(typeof littleEndian === 'boolean', 'missing or invalid endian')
    assert(offset !== undefined && offset !== null, 'missing offset')
    assert(offset + 7 < buf.length,
        'Trying to write beyond buffer length')
    verifIEEE754(value, 1.7976931348623157E+308, -1.7976931348623157E+308)
  }

  var len = buf.length
  if (offset >= len)
    return

  ieee754.write(buf, value, offset, littleEndian, 52, 8)
}

Buffer.prototype.writeDoubleLE = function (value, offset, noAssert) {
  _writeDouble(this, value, offset, true, noAssert)
}

Buffer.prototype.writeDoubleBE = function (value, offset, noAssert) {
  _writeDouble(this, value, offset, false, noAssert)
}

// fill(value, start=0, end=buffer.length)
Buffer.prototype.fill = function (value, start, end) {
  if (!value) value = 0
  if (!start) start = 0
  if (!end) end = this.length

  if (typeof value === 'string') {
    value = value.charCodeAt(0)
  }

  assert(typeof value === 'number' && !isNaN(value), 'value is not a number')
  assert(end >= start, 'end < start')

  // Fill 0 bytes; we're done
  if (end === start) return
  if (this.length === 0) return

  assert(start >= 0 && start < this.length, 'start out of bounds')
  assert(end >= 0 && end <= this.length, 'end out of bounds')

  for (var i = start; i < end; i++) {
    this[i] = value
  }
}

Buffer.prototype.inspect = function () {
  var out = []
  var len = this.length
  for (var i = 0; i < len; i++) {
    out[i] = toHex(this[i])
    if (i === exports.INSPECT_MAX_BYTES) {
      out[i + 1] = '...'
      break
    }
  }
  return '<Buffer ' + out.join(' ') + '>'
}

/**
 * Creates a new `ArrayBuffer` with the *copied* memory of the buffer instance.
 * Added in Node 0.12. Only available in browsers that support ArrayBuffer.
 */
Buffer.prototype.toArrayBuffer = function () {
  if (typeof Uint8Array !== 'undefined') {
    if (Buffer._useTypedArrays) {
      return (new Buffer(this)).buffer
    } else {
      var buf = new Uint8Array(this.length)
      for (var i = 0, len = buf.length; i < len; i += 1)
        buf[i] = this[i]
      return buf.buffer
    }
  } else {
    throw new Error('Buffer.toArrayBuffer not supported in this browser')
  }
}

// HELPER FUNCTIONS
// ================

function stringtrim (str) {
  if (str.trim) return str.trim()
  return str.replace(/^\s+|\s+$/g, '')
}

var BP = Buffer.prototype

/**
 * Augment a Uint8Array *instance* (not the Uint8Array class!) with Buffer methods
 */
Buffer._augment = function (arr) {
  arr._isBuffer = true

  // save reference to original Uint8Array get/set methods before overwriting
  arr._get = arr.get
  arr._set = arr.set

  // deprecated, will be removed in node 0.13+
  arr.get = BP.get
  arr.set = BP.set

  arr.write = BP.write
  arr.toString = BP.toString
  arr.toLocaleString = BP.toString
  arr.toJSON = BP.toJSON
  arr.copy = BP.copy
  arr.slice = BP.slice
  arr.readUInt8 = BP.readUInt8
  arr.readUInt16LE = BP.readUInt16LE
  arr.readUInt16BE = BP.readUInt16BE
  arr.readUInt32LE = BP.readUInt32LE
  arr.readUInt32BE = BP.readUInt32BE
  arr.readInt8 = BP.readInt8
  arr.readInt16LE = BP.readInt16LE
  arr.readInt16BE = BP.readInt16BE
  arr.readInt32LE = BP.readInt32LE
  arr.readInt32BE = BP.readInt32BE
  arr.readFloatLE = BP.readFloatLE
  arr.readFloatBE = BP.readFloatBE
  arr.readDoubleLE = BP.readDoubleLE
  arr.readDoubleBE = BP.readDoubleBE
  arr.writeUInt8 = BP.writeUInt8
  arr.writeUInt16LE = BP.writeUInt16LE
  arr.writeUInt16BE = BP.writeUInt16BE
  arr.writeUInt32LE = BP.writeUInt32LE
  arr.writeUInt32BE = BP.writeUInt32BE
  arr.writeInt8 = BP.writeInt8
  arr.writeInt16LE = BP.writeInt16LE
  arr.writeInt16BE = BP.writeInt16BE
  arr.writeInt32LE = BP.writeInt32LE
  arr.writeInt32BE = BP.writeInt32BE
  arr.writeFloatLE = BP.writeFloatLE
  arr.writeFloatBE = BP.writeFloatBE
  arr.writeDoubleLE = BP.writeDoubleLE
  arr.writeDoubleBE = BP.writeDoubleBE
  arr.fill = BP.fill
  arr.inspect = BP.inspect
  arr.toArrayBuffer = BP.toArrayBuffer

  return arr
}

// slice(start, end)
function clamp (index, len, defaultValue) {
  if (typeof index !== 'number') return defaultValue
  index = ~~index;  // Coerce to integer.
  if (index >= len) return len
  if (index >= 0) return index
  index += len
  if (index >= 0) return index
  return 0
}

function coerce (length) {
  // Coerce length to a number (possibly NaN), round up
  // in case it's fractional (e.g. 123.456) then do a
  // double negate to coerce a NaN to 0. Easy, right?
  length = ~~Math.ceil(+length)
  return length < 0 ? 0 : length
}

function isArray (subject) {
  return (Array.isArray || function (subject) {
    return Object.prototype.toString.call(subject) === '[object Array]'
  })(subject)
}

function isArrayish (subject) {
  return isArray(subject) || Buffer.isBuffer(subject) ||
      subject && typeof subject === 'object' &&
      typeof subject.length === 'number'
}

function toHex (n) {
  if (n < 16) return '0' + n.toString(16)
  return n.toString(16)
}

function utf8ToBytes (str) {
  var byteArray = []
  for (var i = 0; i < str.length; i++) {
    var b = str.charCodeAt(i)
    if (b <= 0x7F)
      byteArray.push(str.charCodeAt(i))
    else {
      var start = i
      if (b >= 0xD800 && b <= 0xDFFF) i++
      var h = encodeURIComponent(str.slice(start, i+1)).substr(1).split('%')
      for (var j = 0; j < h.length; j++)
        byteArray.push(parseInt(h[j], 16))
    }
  }
  return byteArray
}

function asciiToBytes (str) {
  var byteArray = []
  for (var i = 0; i < str.length; i++) {
    // Node's code seems to be doing this and not & 0x7F..
    byteArray.push(str.charCodeAt(i) & 0xFF)
  }
  return byteArray
}

function utf16leToBytes (str) {
  var c, hi, lo
  var byteArray = []
  for (var i = 0; i < str.length; i++) {
    c = str.charCodeAt(i)
    hi = c >> 8
    lo = c % 256
    byteArray.push(lo)
    byteArray.push(hi)
  }

  return byteArray
}

function base64ToBytes (str) {
  return base64.toByteArray(str)
}

function blitBuffer (src, dst, offset, length) {
  var pos
  for (var i = 0; i < length; i++) {
    if ((i + offset >= dst.length) || (i >= src.length))
      break
    dst[i + offset] = src[i]
  }
  return i
}

function decodeUtf8Char (str) {
  try {
    return decodeURIComponent(str)
  } catch (err) {
    return String.fromCharCode(0xFFFD) // UTF 8 invalid char
  }
}

/*
 * We have to make sure that the value is a valid integer. This means that it
 * is non-negative. It has no fractional component and that it does not
 * exceed the maximum allowed value.
 */
function verifuint (value, max) {
  assert(typeof value === 'number', 'cannot write a non-number as a number')
  assert(value >= 0, 'specified a negative value for writing an unsigned value')
  assert(value <= max, 'value is larger than maximum value for type')
  assert(Math.floor(value) === value, 'value has a fractional component')
}

function verifsint (value, max, min) {
  assert(typeof value === 'number', 'cannot write a non-number as a number')
  assert(value <= max, 'value larger than maximum allowed value')
  assert(value >= min, 'value smaller than minimum allowed value')
  assert(Math.floor(value) === value, 'value has a fractional component')
}

function verifIEEE754 (value, max, min) {
  assert(typeof value === 'number', 'cannot write a non-number as a number')
  assert(value <= max, 'value larger than maximum allowed value')
  assert(value >= min, 'value smaller than minimum allowed value')
}

function assert (test, message) {
  if (!test) throw new Error(message || 'Failed assertion')
}

}).call(this,require("pBGvAp"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/../node_modules/buffer/index.js","/../node_modules/buffer")
},{"base64-js":1,"buffer":2,"ieee754":3,"pBGvAp":4}],3:[function(require,module,exports){
(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
exports.read = function (buffer, offset, isLE, mLen, nBytes) {
  var e, m
  var eLen = (nBytes * 8) - mLen - 1
  var eMax = (1 << eLen) - 1
  var eBias = eMax >> 1
  var nBits = -7
  var i = isLE ? (nBytes - 1) : 0
  var d = isLE ? -1 : 1
  var s = buffer[offset + i]

  i += d

  e = s & ((1 << (-nBits)) - 1)
  s >>= (-nBits)
  nBits += eLen
  for (; nBits > 0; e = (e * 256) + buffer[offset + i], i += d, nBits -= 8) {}

  m = e & ((1 << (-nBits)) - 1)
  e >>= (-nBits)
  nBits += mLen
  for (; nBits > 0; m = (m * 256) + buffer[offset + i], i += d, nBits -= 8) {}

  if (e === 0) {
    e = 1 - eBias
  } else if (e === eMax) {
    return m ? NaN : ((s ? -1 : 1) * Infinity)
  } else {
    m = m + Math.pow(2, mLen)
    e = e - eBias
  }
  return (s ? -1 : 1) * m * Math.pow(2, e - mLen)
}

exports.write = function (buffer, value, offset, isLE, mLen, nBytes) {
  var e, m, c
  var eLen = (nBytes * 8) - mLen - 1
  var eMax = (1 << eLen) - 1
  var eBias = eMax >> 1
  var rt = (mLen === 23 ? Math.pow(2, -24) - Math.pow(2, -77) : 0)
  var i = isLE ? 0 : (nBytes - 1)
  var d = isLE ? 1 : -1
  var s = value < 0 || (value === 0 && 1 / value < 0) ? 1 : 0

  value = Math.abs(value)

  if (isNaN(value) || value === Infinity) {
    m = isNaN(value) ? 1 : 0
    e = eMax
  } else {
    e = Math.floor(Math.log(value) / Math.LN2)
    if (value * (c = Math.pow(2, -e)) < 1) {
      e--
      c *= 2
    }
    if (e + eBias >= 1) {
      value += rt / c
    } else {
      value += rt * Math.pow(2, 1 - eBias)
    }
    if (value * c >= 2) {
      e++
      c /= 2
    }

    if (e + eBias >= eMax) {
      m = 0
      e = eMax
    } else if (e + eBias >= 1) {
      m = ((value * c) - 1) * Math.pow(2, mLen)
      e = e + eBias
    } else {
      m = value * Math.pow(2, eBias - 1) * Math.pow(2, mLen)
      e = 0
    }
  }

  for (; mLen >= 8; buffer[offset + i] = m & 0xff, i += d, m /= 256, mLen -= 8) {}

  e = (e << mLen) | m
  eLen += mLen
  for (; eLen > 0; buffer[offset + i] = e & 0xff, i += d, e /= 256, eLen -= 8) {}

  buffer[offset + i - d] |= s * 128
}

}).call(this,require("pBGvAp"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/../node_modules/ieee754/index.js","/../node_modules/ieee754")
},{"buffer":2,"pBGvAp":4}],4:[function(require,module,exports){
(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
// shim for using process in browser

var process = module.exports = {};

process.nextTick = (function () {
    var canSetImmediate = typeof window !== 'undefined'
    && window.setImmediate;
    var canPost = typeof window !== 'undefined'
    && window.postMessage && window.addEventListener
    ;

    if (canSetImmediate) {
        return function (f) { return window.setImmediate(f) };
    }

    if (canPost) {
        var queue = [];
        window.addEventListener('message', function (ev) {
            var source = ev.source;
            if ((source === window || source === null) && ev.data === 'process-tick') {
                ev.stopPropagation();
                if (queue.length > 0) {
                    var fn = queue.shift();
                    fn();
                }
            }
        }, true);

        return function nextTick(fn) {
            queue.push(fn);
            window.postMessage('process-tick', '*');
        };
    }

    return function nextTick(fn) {
        setTimeout(fn, 0);
    };
})();

process.title = 'browser';
process.browser = true;
process.env = {};
process.argv = [];

function noop() {}

process.on = noop;
process.addListener = noop;
process.once = noop;
process.off = noop;
process.removeListener = noop;
process.removeAllListeners = noop;
process.emit = noop;

process.binding = function (name) {
    throw new Error('process.binding is not supported');
}

// TODO(shtylman)
process.cwd = function () { return '/' };
process.chdir = function (dir) {
    throw new Error('process.chdir is not supported');
};

}).call(this,require("pBGvAp"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/../node_modules/process/browser.js","/../node_modules/process")
},{"buffer":2,"pBGvAp":4}],5:[function(require,module,exports){
(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
module.exports = class Events{
  constructor(parent){
    this._parent = parent;
    this._startX = 0;
    this._startY = 0;
    this._focus = false;
    this._component = false;
    this.initEvents();
  }

  initEvents(){
    const board = document.getElementById(`${this._parent._id}_board`);
    const me = this;
    board.addEventListener("mousedown", (e) => {
      const id = e.target.id;
      const component = (e.target.getAttribute('type') == 'component');
      me.startDrag({
        id,
        component,
        x: e.x,
        y: e.y
      });
    });
    board.addEventListener("mouseup", (e) => {
      this.stopDrag({
        x: e.x,
        y: e.y
      });
    });
    board.addEventListener("mousemove", (e) => {
      if(this._focus) this.moveItem({
        x: e.x,
        y: e.y
      })
    });
    board.addEventListener("mouseleave", (e) => {
      if(this._focus) this.mouseLeave()
    });
    board.addEventListener("touchstart", (e) => {
      const id = e.changedTouches[0].target.id;
      const component = (e.changedTouches[0].target.getAttribute('type') == 'component');
      me.startDrag({
        id,
        component,
        x: e.changedTouches[0].clientX,
        y: e.changedTouches[0].clientY
      });
    });
    board.addEventListener("touchmove", (e) => {
      if(this._focus) this.moveItem({
      x: e.changedTouches[0].clientX,
      y: e.changedTouches[0].clientY
    })
  });
    board.addEventListener("touchend", (e) => {
        this.stopDrag({
      x: e.changedTouches[0].clientX,
      y: e.changedTouches[0].clientY
      });
    board.addEventListener("touchcancel", (e) => {
      if(this._focus) this.mouseLeave()
  });
  });
  }

  startDrag(conf){
    const item = document.getElementById(conf.id);
    this._offsetX = conf.x;
    this._offsetY = conf.y;
    this._startX = parseInt(item.style.left.split('px')[0]);
    this._startY = parseInt(item.style.top.split('px')[0]);
    this._focus = conf.id;
    this._component = conf.component;
  }

  moveItem(conf){
    const board = document.getElementById(`${this._parent._id}_board`);
    const left = parseInt(window.getComputedStyle(board,null).getPropertyValue("left").split('px')[0]);
    const right = parseInt(window.getComputedStyle(board,null).getPropertyValue("right").split('px')[0]);
    const top = parseInt(window.getComputedStyle(board,null).getPropertyValue("top").split('px')[0]);
    const bottom = parseInt(window.getComputedStyle(board,null).getPropertyValue("bottom").split('px')[0]);
    const xDir = this._lastX - conf.x;
    const yDir = this._lastY - conf.y;

    if(((left < (this._parent.View._width / 2) || xDir > 0 ) && (right < ((this._parent.View._width / 2))) || xDir < 0 ) || this._component) document.getElementById(this._focus).style.left = this._startX + (conf.x - this._offsetX);
    if(((top < (this._parent.View._width / 2) || yDir > 0 ) && (bottom < ((this._parent.View._width / 2))) || yDir < 0 ) || this._component) document.getElementById(this._focus).style.top = this._startY + (conf.y - this._offsetY);


    if(!this._component) {
      if (parseInt(window.getComputedStyle(board, null).getPropertyValue("left").split('px')[0]) > (this._parent.View._width / 2)) {
        document.getElementById(this._focus).style.left = (this._parent.View._width / 2)
      }

      if (parseInt(window.getComputedStyle(board, null).getPropertyValue("top").split('px')[0]) > (this._parent.View._height / 2)) {
        document.getElementById(this._focus).style.top = (this._parent.View._height / 2)
      }

      if ((parseInt(window.getComputedStyle(board, null).getPropertyValue("right").split('px')[0]) > (this._parent.View._width / 2))) {
        document.getElementById(this._focus).style.left = -((this._parent.View._width / 2)) + this._parent.View._wrapperX
      }

      if ((parseInt(window.getComputedStyle(board, null).getPropertyValue("bottom").split('px')[0]) > (this._parent.View._height / 2))) {
        document.getElementById(this._focus).style.top = -((this._parent.View._height / 2)) + this._parent.View._wrapperY
      }
    }

    this._lastX = conf.x;
    this._lastY = conf.y;
  }

  stopDrag(conf){
    const x = this._startX + (conf.x - this._offsetX);
    const y = this._startY + (conf.y - this._offsetY);
    const newXY = this._parent.View.convertXY(x, y, 'component');
    if(this._component) this._parent.View.getItem(this._focus).setLocalPosition(newXY.x, newXY.y);
    else{
      const left = document.getElementById(this._focus).style.left.split('px')[0];
      const top = document.getElementById(this._focus).style.top.split('px')[0];
      const boardXY = this._parent.View.getPosition();
      window.location.hash = boardXY.x+','+boardXY.y;
    }
    this._focus = false;
  }

  mouseLeave(){
    document.getElementById(this._focus).style.left = this._startX;
    document.getElementById(this._focus).style.top = this._startY;
    this._focus = false;
    // $('#'+this._focus).animate({
    //   left: this._startX,
    //   top: this._startY
    // }, 200);
  }
}

}).call(this,require("pBGvAp"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/Events.js","/")
},{"buffer":2,"pBGvAp":4}],6:[function(require,module,exports){
(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
module.exports = class Generator {
    constructor(props) {
        this._board = this.generateBoard({
            id: props.id || false,
            name: props.name || false,
            desc: props.desc || false,
            width: props.width || false,
            height: props.height || false
        });
        this._items = this.generateItems(props.items);
        this._theme = this.generateTheme(props.theme);

        return {
            board: this._board,
            items: this._items,
            theme: this._theme,
            exists: true // Required to align with the sockets feature (handles a scenario where the board doesn't yet exist)
        }
    }

    _getDefaultConfig() {
        return {
            target: 'root',
            id: 'default',
            width: 800,
            height: 720,
            items: {
                spawn: 141,
                options: {
                    text: ['th','th','th','th', 'sh','sh','sh','sh', 'ch','ch','ch','ch', 'ck','ck','ck','ck', 'wh','wh','wh','wh', 'tch','tch','tch','tch', 'a', 'a', 'a', 'a', 'b', 'b', 'b', 'b', 'c', 'c', 'c', 'c', 'd', 'd', 'd', 'd', 'e', 'e', 'e', 'e', 'ə', 'ə', 'ə', 'ə',  'f', 'f', 'f', 'f', 'g', 'g', 'g', 'g', 'h', 'h', 'h', 'h', 'i', 'i', 'i', 'i', 'i', 'j', 'j', 'j', 'j', 'k', 'k', 'k', 'k', 'l', 'l', 'l', 'l', 'l', 'm', 'm', 'm', 'm', 'm', 'n', 'n', 'n', 'n', 'n', 'o','o','o','o','o', 'p', 'p', 'p', 'p', 'qu', 'qu', 'qu', 'qu', 'r', 'r', 'r', 'r', 's','s','s','s', 't','t','t','t', 'u', 'u', 'u', 'u', 'v', 'v','v','v','w', 'w','w','w','x', 'x','x','x','y', 'y','y','y','z','z','z','z',' ',' ',' ',' '], ///
                    color: [1,2],
                    type: 'Letter'
                }
            },
            theme: [{
                    code: "background",
                    id: 1,
                    key: "rgb",
                    value: "255,255,255"
                },
                {
                    code: "color",
                    id: 1,
                    key: "rgb",
                    value: "74, 150, 204"
                },
                {
                    code: "color",
                    id: 2,
                    key: "rgb",
                    value: "233, 220, 49"
                },
                {
                    code: "color",
                    id: 3,
                    key: "rgb",
                    value: "242, 141, 63"
                },
                {
                    code: "color",
                    id: 4,
                    key: "rgb",
                    value: "218, 65, 67"
                },
                {
                    code: "color",
                    id: 5,
                    key: "rgb",
                    value: "65, 130, 66"
                },
                {
                    code: "letter",
                    id: 1,
                    key: "fontFamily",
                    value: "Arial, 'Helvetica Neue', Helvetica, sans-serif"
                },
                {
                    code: "letter",
                    id: 1,
                    key: "textShadow",
                    value: ""
                }
            ]
        };
    }


    generateBoard(board) {
        return Object.assign(
            board, {
                name: board.name ? board.name : "Empty",
                desc: board.desc ? board.desc : "No Description",
                width: board.width ? board.width : 1024,
                height: board.height ? board.height : 720
            })
    }

    generateItems(items) {

        if (!items) {
            items = this._getDefaultConfig().items;
        }

        if (typeof items == 'array') {
            return items;
        }

        var currentLetter = "";
        var prevLetter = "";
        var letterline = 90;
        var letterSpacing = 70;
        var resetLetterLine = "0";
        var xPos = 0;

        return [...Array(items.spawn || this._getDefaultConfig().items.spawn)].map((item, index) => {

            var currentLetter = items.options.text[index];

            // Set defult color
            items.options.color[index] = 1;

            if(currentLetter == "a" || currentLetter == "e" || currentLetter == "i" || currentLetter == "o" || currentLetter == "u") { //currentLetter == "y"
                items.options.color[index] = 2;
            }

            if (prevLetter == "") {
                letterline = 20;
                letterSpacing = 100;
                xPos = 150;
            } else if (currentLetter == "a"){
                letterline = 100;
                letterSpacing = 70;
                xPos = -55;
            } else if (currentLetter == "n"){
                letterline = 180;
                xPos = -55;
            }

            if(prevLetter != currentLetter || prevLetter == "a" || prevLetter == "n"){
                xPos = xPos + letterSpacing;
            }

            const location = {
                x: -(this._board.width / 2) + xPos,
                y: -(this._board.height / 2) + letterline
            };


            //this.generateLocation();

            const extraValues = {};

            for (const key of Object.keys(items && items.options)) {
                if (Array.isArray(items.options[key])) {
                    extraValues[key] = items.options[key][index];
                } else {
                    extraValues[key] = items.options[key];
                }
            }

            prevLetter = extraValues.text;



            return Object.assign({
                    id: index
                },
                location,
                extraValues);

                prevLetter = extraValues.text;
        })

    }

    generateTheme(theme) {
        if (!theme) return this._getDefaultConfig().theme;
        return theme;
    }

    pickRandomFromArray(array) {
        return array[Math.floor(Math.random() * array.length)];
    }

    generateLocation() {
        return {
            x: (Math.floor(Math.random() * (this._board.width + 1))) - (this._board.width / 2),
            y: -(this._board.height / 2) + 80 // (Math.floor(Math.random() * (this._board.height + 1))) - (this._board.height / 2)
        }
    }

}

}).call(this,require("pBGvAp"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/Generator.js","/")
},{"buffer":2,"pBGvAp":4}],7:[function(require,module,exports){
(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
module.exports = class Mixins{
  constructor(){
  }

  /**
   * Apply styles to dom object
   */
  applyStyle(dom, style, params){
    const arrayStyle = (typeof style.length != 'undefined') ? style : Object.entries(style);
    for(style of arrayStyle){
      dom.style[style[0]] = this.applyTemplate(style[1], params);
    }
    return dom;
  }

  /**
   * Apply template information
   */
  applyTemplate(value, params){
    if(value.indexOf('[') != -1) value = value.replace(/\[r\]/g, params.r).replace(/\[g\]/g, params.g).replace(/\[b\]/g, params.b);
    if(value.indexOf('{') !=- 1) {
      const regex = value.match(/\{([^}]+)\}/g);
      for (const match of regex) {
        value = value.replace(match, eval(match));
      }
    }
    return value;
  }

  /**
   * Convert css object to string
   */
  convertToCSSString(cssObject){
    const entries = Object.entries(cssObject);
    const cssArray = [];
    for(const entry of entries){
      for(let i = 0; i < entry[0].length; i++){
        if(entry[0].charAt(i) === entry[0].charAt(i).toUpperCase()){
          entry[0] = entry[0].slice(0,i)+'-'+entry[0].slice(i, entry[0].length).toLowerCase();
        }
      }
      cssArray.push(entry[0]+': '+entry[1]);
    }
    return cssArray.join('; ');
  }

  makeAnimation(item, value, to, duration){
    const start = item.style[value].split('px')[0];
    const change = to - start;
    const increment = 10;
    let currentTime = 0;
    const easeInOutQuad = function(t, b, c, d) {
      t /= d/2;
      if (t < 1) return c/2*t*t + b;
      t--;
      return -c/2 * (t*(t-2) - 1) + b;
    };

    var animateScroll = function(){
      currentTime += increment;
      var val = easeInOutQuad(currentTime, start, change, duration);
      // console.log(value, (parseFloat(start) + parseFloat(val)), val, duration, currentTime);
      item.style[value] = currentTime == duration ? to : (parseFloat(start) + parseFloat(val));
      if(currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();

  }
}
}).call(this,require("pBGvAp"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/Mixins.js","/")
},{"buffer":2,"pBGvAp":4}],8:[function(require,module,exports){
(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
const Generator = require('./Generator');

module.exports = class Socket{
  constructor(parent){
    this._parent = parent;
    this.initSocket();
  }

  /**
   * Init socket events
   */
  initSocket(){
    const me = this;
    this._socketConnected = false;
    if(typeof io == 'undefined'){
      console.info('Socket.io not enabled, generating static board');
      this._socketEnabled = false;
      this._socket = {
        emit: () => {
          return false;
        }
      }
      const generatedBoard = this._parent._config;
      me.onLoad(generatedBoard);
    }
    else{
      this._socketEnabled = true;
      this._socket = io.connect('ws://www.online-fridge.com');
      this._socket.on('connect', () => {
        me.onSocketConnected();
      });
        this._socket.on('disconnect', () => {
          me.onSocketDisconnected();
      });
        this._socket.on('error', (err) => {
          me.onSocketError(err);
      });
        this._socket.on('authenticate', (data) => {
          me.onAuthenticate(data);
      })
        this._socket.on('load', (data) => {
          me.onLoad(data);
      })
        this._socket.on('move', (data) => {
          me.onMove(data);
      })
        this._socket.on('add', (data) => {
          me.onAdd(data);
      })
        this._socket.on('noExist', (data) => {
          me._parent.View.askCreate();
      })
        this._socket.on('stats', (data) => {
          me._parent.View.updateStats(data);
      })
    }
  }

  /**
   * On Authenticate
   */
  onAuthenticate(data){
    this._parent.getBoard(data);
  }

  /**
   * On Load
   */
  onLoad(data){
    this._parent.setBoard(data)
  }

  /**
   * On Move
   */
  onMove(data){
    for(const move of data){
      if(move.time) this._parent.View.getItem(move.id).setRemotePosition(move.x, move.y, move.time)
      else this._parent.View.getItem(move.id).setRemotePosition(move.x, move.y)
    }
  }

  /**
   * On Item Add
   */
  onAdd(data){
    this._parent.View.addItem(data);
  }

  /**
   * On Socket Connect
   */
  onSocketConnected(){
    this._socketConnected = true;
    console.log(`${this._parent._id.split('#')[0]}: Connected to socket`);
    this.authenticate();
  }

  /**
   * Authenticate Board
   */
  authenticate(){
    console.log('authenticating');
    this._socket.emit('authenticate', {id: this._parent._id});
  }

  /**
   * On Socket Disconnected
   */
  onSocketDisconnected(){
    this._socketConnected = false;
    console.log(`${this._parent._id}: Socket disconnected`);
  }

  /**
   * On Socket Error
   */
  onSocketError(err){
    this._socketConnected = false;
    console.log(error);
  }
}

}).call(this,require("pBGvAp"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/Socket.js","/")
},{"./Generator":6,"buffer":2,"pBGvAp":4}],9:[function(require,module,exports){
(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
module.exports = class Theme{

  /**
   * Initiate theme with config
   * @param theme
   */
  constructor(theme, parent){
    this._parent = parent;
    this._items = new Map();
    this._colors = new Map();
    this.setTheme(theme);
  }

  /**
   *  Set theme state
   */
  setTheme(theme){
    for(const item of theme){
      switch(item.key){
        case 'rgb':{
          this._colors.set((item.code == 'color') ? item.id : item.code, {
            r: item.value.split(',')[0],
            g: item.value.split(',')[1],
            b: item.value.split(',')[2]
          })
          break;
        }
        default:{
          if(!this._items.has(item.code)) {
            this._items.set(item.code, theme.filter(other => (other.code == item.code)).map( item => ([item.key, item.value])));
          }
        }
      }
    }
  }

  /**
   * Apply theme to dom object
   */
  applyTheme(dom, code, params){
    //debugger;
    if(this._items.has(code)){
      // debugger;
      return this._parent.Mixins.applyStyle(dom, this.getItem(code), params);
    }
    return dom;
  }

  /**
   * Apply background to dom object
   */
  applyBackground(dom){
    dom.style.background = this.getColorCSS('background');
    return dom;
  }

  /**
   *  Get theme item array
   */
  getItem(code){
    //console.log('getting: ', this._items.get(code));
    return this._items.get(code);
  }

  /**
   * Get color rgb values as object
   */
  getColor(key){
    return this._colors.get(key)
  }

  /**
   * Get color as rgb string (CSS)
   */
  getColorCSS(key){
    const color =  this._colors.get(key);
    return `rgb(${color.r},${color.g},${color.b})`;
  }
}

}).call(this,require("pBGvAp"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/Theme.js","/")
},{"buffer":2,"pBGvAp":4}],10:[function(require,module,exports){
(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
module.exports = class View{
  constructor(me){
    this._parent = me;
    this._items = new Map();
    this._classNames = new Set();
    this.initCSS();
    this.buildDOM();
    this.setWrapperSize();
    this.setEvents();
  }

  /**
   * Initialise CSS Objects
   */
  initCSS(){
    this._wrapperCSS = {
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      position: 'relative'
    }

    this._boardCSS = {
      width: '100%',
      height: '100%',
      //position: 'absolute',
      background: 'white'
    }
  }

  /**
   * Build DOM
   */
  buildDOM(){
    const root = document.getElementById(this._parent._root);
    const wrapper = document.createElement("div");
    wrapper.id = `${this._parent._id}_wrapper`;
    wrapper.className = "wrapper";
    this._parent.Mixins.applyStyle(wrapper, this._wrapperCSS);
    const board = document.createElement("div");
    board.id = `${this._parent._id}_board`;
    this._parent.Mixins.applyStyle(board, this._boardCSS);
    const stats = document.createElement('div');
    stats.id = 'members';
    stats.className = 'statsToggle';
    const spinner = document.createElement('div');
    const dot1 = document.createElement('div');
    const dot2 = document.createElement('div');
    spinner.className = 'spinner';
    spinner.id = 'spinner';
    dot1.className = 'dot1';
    dot2.className = 'dot2';
    spinner.appendChild(dot1);
    spinner.appendChild(dot2);
    wrapper.appendChild(board);
    root.innerHTML = '';
    root.appendChild(wrapper);
    root.appendChild(stats);
    root.appendChild(spinner);
  }

  /**
   * Add Item
   */
  addItem(item){
    this.setItem(item.id, new this._parent.component[item.type](item, this._parent));
    this.renderItems();
  }

  /**
   * Ask To Create Board (If Board doesn't exist)
   */
  askCreate(){
    const me = this;
    const message = document.createElement("div");
    message.className = "message";
    message.innerHTML = `
    Fridge "${this._parent._id}" is available!<br>
    `
    const button = document.createElement("button");
    button.innerHTML = 'Create';
    message.id = 'message';
    button.addEventListener('click', () => {
      document.getElementsByClassName('message').outerHTML = '';
      document.getElementById(`${this._parent._id}_board`).removeChild(document.getElementById('message'));
      me._parent.Socket._socket.emit('start', {id: me._parent._id});
    });

    message.appendChild(button);

    document.getElementById(`${this._parent._id}_board`).appendChild(message);
    this.fadeIn();
  }

  /**
   * Set View Events (Resize window etc)
   */
  setEvents(){
    const me = this;
    window.onresize = function(event) {
      me.setWrapperSize();
      me.loadPosition();
    };
  }

  /**
   * Set Board size
   */
  setSize(width, height){
    this._width = width;
    this._height = height;
    const board = document.getElementById(`${this._parent._id}_board`);
    board.style.width = this._width+'px';
    board.style.height = this._height+'px';
    //board.style.margin = `-${this._height / 2}px -${this._width / 2}px`;
  }

  /**
   * Set board Position
   */
  setPosition(x, y){
    this._x = x;
    this._y = y;
    this.loadPosition();
  }

  /**
   * Get board position
   */
  getPosition(){
    const board = document.getElementById(`${this._parent._id}_board`);
    return {
      x: Math.floor((parseInt(board.style.left.split('px')[0]) - (this._wrapperX / 2))),
      y: Math.floor(parseInt(board.style.top.split('px')[0]) - (this._wrapperY / 2))
    }
  }

  setWrapperSize(){
    const wrapper = document.getElementById(`${this._parent._id}_wrapper`);
    this._wrapperX = document.getElementById(this._parent._root).offsetWidth;
    this._wrapperY = document.getElementById(this._parent._root).offsetHeight;
  }

  loadPosition(){
    const board = document.getElementById(`${this._parent._id}_board`);
    board.style.top = `${parseInt((this._wrapperY / 2)) + parseInt(this._y)}px`;
    board.style.left = `${parseInt((this._wrapperX / 2)) + parseInt(this._x)}px`;
  }

  setTheme(){
    this._parent.Theme.applyBackground(document.getElementById(`${this._parent._id}_board`));
  }

  setItem(id, object){
    this._items.set(id, object)
  }

  getItem(id){
    return this._items.get(parseInt(id));
  }

  convertXY(x, y, target){
    if(target == 'board') {
      const board = document.getElementById(`${this._parent._id}_board`);
      return {
        x: x + (parseInt(board.style.width.split('px')[0]) / 2),
        y: y + (parseInt(board.style.height.split('px')[0]) / 2),
      }
    }
    else{
      const board = document.getElementById(`${this._parent._id}_board`);
      return {
        x: x - (parseInt(board.style.width.split('px')[0]) / 2),
        y: y - (parseInt(board.style.height.split('px')[0]) / 2),
      }
    }
  }

  updateStats(stats){
    document.getElementById('members').innerHTML = stats.members+' Online';
  }

  hideLoad(){
    document.getElementById('spinner').style.display = 'none';
  }

  /**
   * Fade the board into view
   */
  fadeIn(){
    document.getElementsByClassName('wrapper')[0].style.opacity = 1;
  }

  fadeInStats(){
    document.getElementsByClassName('statsToggle')[0].style.opacity = 1;
  }

  renderItems(){
    this.hideLoad();
    for(const item of [...this._items.values()]){
      if(!document.getElementById(item.getItem().id)) {
        const board = document.getElementById(`${this._parent._id}_board`);
        const itemDiv = item.getItem();
        board.appendChild(itemDiv);
      }
    }
  }

  /**
   * Create a css class from an object
   */
  addCSSClass(className, classDetails){
    const style = document.createElement('style');
    style.type = 'text/css';
    style.innerHTML = `.${className} { ${this._parent.Mixins.convertToCSSString(classDetails)} }`;
    document.getElementsByTagName('head')[0].appendChild(style);
    this._classNames.add(className);
  }

  /**
   * Has the class been dynamicaly loaded?
   */
  hasClass(className){
    return this._classNames.has(className);
  }
}

}).call(this,require("pBGvAp"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/View.js","/")
},{"buffer":2,"pBGvAp":4}],11:[function(require,module,exports){
(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
const Mixins = require('../Mixins');

module.exports = class Component {
  constructor(config, parent){
    this._parent = parent;
    this._id = config.id;
    this._x = config.x;
    this._y = config.y;
    this._type = 'component';
  }

  /**
   * Initialise default values (css class defaults)
   */
  init(){
    this._defaultClass = {
      position: 'absolute',
      transform: 'translate(-50%, -50%)',
      cursor: 'pointer',
      //padding: '0px',
      userSelect: 'none'
    }
  }

  /**
   * Return item as dom object
   */
  getItem(){
    const r = this._parent.Theme.getColor(this._color).r;
    const g = this._parent.Theme.getColor(this._color).g;
    const b = this._parent.Theme.getColor(this._color).b;
    const itemDiv = document.createElement("div");
    const xy = this._parent.View.convertXY(this._x, this._y, 'board');
    itemDiv.id = this._id;
    itemDiv.className = this._type + " " + this._text;
    itemDiv.innerHTML = this._text;
    itemDiv.style.top = xy.y;
    itemDiv.style.left = xy.x;
    itemDiv.setAttribute('type', 'component');
    this._parent.Mixins.applyStyle(itemDiv, this._defaultTheme, {r,g,b});
    this._parent.Theme.applyTheme(itemDiv, 'letter', {r,g,b});
    return itemDiv;
  }

  /**
   * Set the position of the item as and emit to socket (as if it was set by the current user)
   */
  setLocalPosition(x, y){
    this._x = x;
    this._y = y;
    console.log(`${this._type} ${this._id}: x(${Math.floor(x)}) y(${Math.floor(y)})`);
    this._parent.Socket._socket.emit('move', {
      id: this._id,
      x: this._x,
      y: this._y
    })
  }

  /**
   * Set the position of the item and create pulse animation (as if it was set by a different user)
   */
  setRemotePosition(x, y, time){
    this._x = x;
    this._y = y;
    this.animateSetPosition(time);
    if(typeof this.moveAnimation === 'function') this.moveAnimation();
  }

  /**
   * Animate the movement of the item
   */
  animateSetPosition(time){
    const xy = this._parent.View.convertXY(this._x, this._y, 'board');
    const item = document.getElementById(this._id);

    this._parent.Mixins.makeAnimation(item, 'left', xy.x, time || 200);
    this._parent.Mixins.makeAnimation(item, 'top', xy.y, time || 200);

    // $('#'+this._id).animate({
    //   left: xy.x,
    //   top: xy.y
    // }, time || 200);
  }
}

}).call(this,require("pBGvAp"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/component/Component.js","/component")
},{"../Mixins":7,"buffer":2,"pBGvAp":4}],12:[function(require,module,exports){
(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
const Component = require('./Component.js')

module.exports = class Letter extends Component{
  constructor(config, parent){
    super(config, parent);
    this._text = config.text;
    this._color = config.color;
    this._type = 'letter';
    if(!this._parent.View.hasClass(this._type)) this.init();
    this.initCustom();
  }

  init(){
    /**
     * Add parent default class information
     */
    super.init();
    /**
     * Insert custom default class information
     */
    this._defaultClass = Object.assign({
      fontStyle: 'normal',
      fontSize: '45px',
      lineHeight: '40px',
      //color: 'rgb([r],[g],[b])',
      color: '#000',
      height: '44px',
      margin: '30px',
      padding: '20px',
      paddingBottom: '10px',
      paddingTop: '10px',
      display: 'block',
      border: '1px solid black'
    },this._defaultClass);
    /**
     * Dynamically insert class
     */
    this._parent.View.addCSSClass(this._type, this._defaultClass);
  }

  initCustom(){
    this._defaultTheme = {
      backgroundColor: 'rgb([r],[g],[b])'  //color: 'rgb([r],[g],[b])',
    };
  }

  moveAnimation(){
    const pulse = document.createElement('div');
    const me = this;
    pulse.id = 'pulse_'+this._id;
    pulse.className = 'pulse';
    pulse.style.borderColor = `rgb(${this._parent.Theme.getColor(this._color)})`;
    document.getElementById(this._id).appendChild(pulse);
    setTimeout(() => { document.getElementById(me._id).removeChild(pulse); },10000);
  }

}

}).call(this,require("pBGvAp"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/component/Letter.js","/component")
},{"./Component.js":11,"buffer":2,"pBGvAp":4}],13:[function(require,module,exports){
(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
const Letter = require('./Letter.js')

module.exports = {
  Letter
}
}).call(this,require("pBGvAp"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/component/index.js","/component")
},{"./Letter.js":12,"buffer":2,"pBGvAp":4}],14:[function(require,module,exports){
(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
const component = require('./component/index.js')
const View = require('./View.js')
const Theme = require('./Theme.js')
const Events = require('./Events.js')
const Socket = require('./Socket.js')
const Mixins = require('./Mixins.js')
const Generator = require('./Generator')

module.exports = window.Magnet;

window.Magnet = class Magnet {
  constructor(config){
    this.initConfig(config);
    // this.initClasses();
  }

  /**
   * Mount the board
   */
  mount(){
    console.log('Magnet started');
    this.initClasses();
  }

  /**
   * Initialise Config
   * @param target
   * @param id
   */
  initConfig(config, id){
    this._root = config.target;
    this._id = config.id ? config.id : 'local';
    this._name = '';
    this._description = '';
    this._config = new Generator(config)
  }

  /**
   * Initialise Classes
   */
  initClasses(){
    this.Mixins = new Mixins(this);
    this.component = component;
    this.View = new View(this);
    this.Socket = new Socket(this);
    this.Events = new Events(this);
  }

  /**
   * Return root dom
   */
  getRoot(){
    return document.getElementById(this._root);
  }

  /**
   * Get Board data
   */
  getBoard(data){
    this.Socket._socket.emit('load');
  }

  /**
   * Set Board from Data
   */
  setBoard(data){
    this.setTheme(data.theme);
    this.setDetails(data.board);
    this.setItems(data.items);
    this.View.fadeIn();
    this.View.fadeInStats();
  }

  /**
   * Set board theme
   */
  setTheme(theme){
    this.Theme = new Theme(theme, this);
    this.View.setTheme();
  }

  /**
   * Set board details
   */
  setDetails(details){
    this._name = details.name;
    this._description = details.desc;
    this.View.setSize(details.width, details.height);
    this.View.setPosition(window.location.hash.split(',')[0].split('#')[1] || 0,window.location.hash.split(',')[1] || 0);
  }

  /**
   * Set board items
   */
  setItems(items){
    for(const item of items){
      this.View.setItem(item.id, new this.component[item.type](item, this));
    }
    this.View.renderItems();
  }
}
}).call(this,require("pBGvAp"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/fake_f19a62b9.js","/")
},{"./Events.js":5,"./Generator":6,"./Mixins.js":7,"./Socket.js":8,"./Theme.js":9,"./View.js":10,"./component/index.js":13,"buffer":2,"pBGvAp":4}]},{},[14])

