(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
module.exports = class Generator {
    constructor(props) {
        this._board = this.generateBoard({
            id: props.id || false,
            name: props.name || false,
            desc: props.desc || false,
            width: props.width || false,
            height: props.height || false
        });
        this._items = this.generateItems(props.items);
        this._theme = this.generateTheme(props.theme);

        return {
            board: this._board,
            items: this._items,
            theme: this._theme,
            exists: true // Required to align with the sockets feature (handles a scenario where the board doesn't yet exist)
        }
    }

    _getDefaultConfig() {
        return {
            target: 'root',
            id: 'default',
            width: 800,
            height: 720,
            items: {
                spawn: 141,
                options: {
                    text: ['th','th','th','th', 'sh','sh','sh','sh', 'ch','ch','ch','ch', 'ck','ck','ck','ck', 'wh','wh','wh','wh', 'tch','tch','tch','tch', 'a', 'a', 'a', 'a', 'b', 'b', 'b', 'b', 'c', 'c', 'c', 'c', 'd', 'd', 'd', 'd', 'e', 'e', 'e', 'e', 'ə', 'ə', 'ə', 'ə',  'f', 'f', 'f', 'f', 'g', 'g', 'g', 'g', 'h', 'h', 'h', 'h', 'i', 'i', 'i', 'i', 'i', 'j', 'j', 'j', 'j', 'k', 'k', 'k', 'k', 'l', 'l', 'l', 'l', 'l', 'm', 'm', 'm', 'm', 'm', 'n', 'n', 'n', 'n', 'n', 'o','o','o','o','o', 'p', 'p', 'p', 'p', 'qu', 'qu', 'qu', 'qu', 'r', 'r', 'r', 'r', 's','s','s','s', 't','t','t','t', 'u', 'u', 'u', 'u', 'v', 'v','v','v','w', 'w','w','w','x', 'x','x','x','y', 'y','y','y','z','z','z','z',' ',' ',' ',' '], ///
                    color: [1,2],
                    type: 'Letter'
                }
            },
            theme: [{
                    code: "background",
                    id: 1,
                    key: "rgb",
                    value: "255,255,255"
                },
                {
                    code: "color",
                    id: 1,
                    key: "rgb",
                    value: "74, 150, 204"
                },
                {
                    code: "color",
                    id: 2,
                    key: "rgb",
                    value: "233, 220, 49"
                },
                {
                    code: "color",
                    id: 3,
                    key: "rgb",
                    value: "242, 141, 63"
                },
                {
                    code: "color",
                    id: 4,
                    key: "rgb",
                    value: "218, 65, 67"
                },
                {
                    code: "color",
                    id: 5,
                    key: "rgb",
                    value: "65, 130, 66"
                },
                {
                    code: "letter",
                    id: 1,
                    key: "fontFamily",
                    value: "Arial, 'Helvetica Neue', Helvetica, sans-serif"
                },
                {
                    code: "letter",
                    id: 1,
                    key: "textShadow",
                    value: ""
                }
            ]
        };
    }


    generateBoard(board) {
        return Object.assign(
            board, {
                name: board.name ? board.name : "Empty",
                desc: board.desc ? board.desc : "No Description",
                width: board.width ? board.width : 1024,
                height: board.height ? board.height : 720
            })
    }

    generateItems(items) {

        if (!items) {
            items = this._getDefaultConfig().items;
        }

        if (typeof items == 'array') {
            return items;
        }

        var currentLetter = "";
        var prevLetter = "";
        var letterline = 90;
        var letterSpacing = 70;
        var resetLetterLine = "0";
        var xPos = 0;

        return [...Array(items.spawn || this._getDefaultConfig().items.spawn)].map((item, index) => {

            var currentLetter = items.options.text[index];

            // Set defult color
            items.options.color[index] = 1;

            if(currentLetter == "a" || currentLetter == "e" || currentLetter == "i" || currentLetter == "o" || currentLetter == "u") { //currentLetter == "y"
                items.options.color[index] = 2;
            }

            if (prevLetter == "") {
                letterline = 20;
                letterSpacing = 100;
                xPos = 150;
            } else if (currentLetter == "a"){
                letterline = 100;
                letterSpacing = 70;
                xPos = -55;
            } else if (currentLetter == "n"){
                letterline = 180;
                xPos = -55;
            }

            if(prevLetter != currentLetter || prevLetter == "a" || prevLetter == "n"){
                xPos = xPos + letterSpacing;
            }

            const location = {
                x: -(this._board.width / 2) + xPos,
                y: -(this._board.height / 2) + letterline
            };


            //this.generateLocation();

            const extraValues = {};

            for (const key of Object.keys(items && items.options)) {
                if (Array.isArray(items.options[key])) {
                    extraValues[key] = items.options[key][index];
                } else {
                    extraValues[key] = items.options[key];
                }
            }

            prevLetter = extraValues.text;



            return Object.assign({
                    id: index
                },
                location,
                extraValues);

                prevLetter = extraValues.text;
        })

    }

    generateTheme(theme) {
        if (!theme) return this._getDefaultConfig().theme;
        return theme;
    }

    pickRandomFromArray(array) {
        return array[Math.floor(Math.random() * array.length)];
    }

    generateLocation() {
        return {
            x: (Math.floor(Math.random() * (this._board.width + 1))) - (this._board.width / 2),
            y: -(this._board.height / 2) + 80 // (Math.floor(Math.random() * (this._board.height + 1))) - (this._board.height / 2)
        }
    }

}

}).call(this,require("pBGvAp"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/Generator.js","/")